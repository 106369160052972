<template>
  <div>
    <div class="d-flex ">
      <b-col
        md="3"
      >
        <b-form-group
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Groups"
        >
          <v-select
            v-model="group"
            :clearable="false"
            placeholder="group"
            label="group_code"
            :options="groups"
            @input="getNotes"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->

    </div>

    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Semestere 1 :
    </h4>
    <vue-good-table
      :columns="columns"
      :rows="notes"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'teaching-units-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteTeachingUnit(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Semestere 2 :
    </h4>
    <vue-good-table
      :columns="columnss"
      :rows="notes2"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'teaching-units-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteTeachingUnit(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BFormGroup, BTooltip, BCol,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

// import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BFormGroup,
    BTooltip,
    BCol,
    vSelect,

  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      semesters: ['semester 1', 'semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'N°',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Identité',
          field: 'student.id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom & Prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Moyenne Semester 1',
          field: 'moy_semester',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Date Modif',
          field: 'updated_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },

      ],
      columnss: [
        {
          label: 'N°',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Identité',
          field: 'student.id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom & Prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Moyenne Semester 2',
          field: 'moy_semester_2',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Date création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },
        {
          label: 'Date Modif',
          field: 'updated_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },

      ],

      paiements: [],
      inscriptions: [],
      students: [],
      student: {},
      username: '',
      discounts: [],
      notes: [],
      notes2: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},
      semester1: [],
      semester2: [],
      groups: [],
      group: {},
      subjects: [],
      subjectt: [],
      subject: {},
      noteStudents: [],
      semesterNote: [],
      moyennes: [],
      deliberationResults: [],

    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },
    notezero() {
      return [this.notes[0]]
      // this.testSubject()
      // return this.semesterNote
    },
    moyennezero() {
      return [this.notes.deliberation_set[0]]
      // this.testSubject()
      // return this.semesterNote
    },
    result() {
      return this.pushDeliberation()
    },
  },
  created() {
    this.getGroups()
    this.getAcademicYears()
    this.getStudents()
    this.getNotes()
    this.getNotesSesmester2()
    this.user = storeAuth.state.user
    this.student_id = this.$route.params.id
    this.currentAcademicYear = storeAuth.state.currentAcademicYear
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    async pushDeliberation() {
      const newArr = this.notes.map(note => this.deliberationResults.push(note.deliberation_set[0]))
      this.deliberationResults = newArr
      // console.log('checking note in method', newArr)
      // console.log('checking my obj', this.deliberationResults)
    },
    listnotes() {
      this.semesterNote.push(this.notes[0])
    },
    moyenneSemester() {
      this.moyennes.push(this.notes.deliberation_set[0])
    },
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getGroups() {
      const response = await axios.get('/api/groups/')
      this.groups = response.data
    },
    async getStudents() {
      const response = await axios.get('/api/authentication/students/')
      this.students = response.data
    },
    async getNotes() {
      this.load = 'true'
      const response = await axios.get('/api/deliberations/deliberation-semester1', {
        params: {
          academic_year_id: this.currentAcademicYear.id, group_id: this.group.id,
        },
      })
      this.notes = response.data
      // const result = response.data
      // const result = response.data
      // this.semester1 = result.filter(x => x.semester === 'semester 1')
      // this.semester2 = result.filter(x => x.semester === 'semester 2')
      // this.semester1 = result.filter(x => x.semester === 'semester 1')
      // this.semester2 = result.filter(x => x.semester === 'semester 2')
      // this.semaines = response.data
      this.load = 'false'
    },

    async getNotesSesmester2() {
      this.load = 'true'
      const response = await axios.get('/api/deliberations/deliberation-semester2', {
        params: {
          academic_year_id: this.currentAcademicYear.id, group_id: this.group.id,
        },
      })
      this.notes2 = response.data
      // const result = response.data
      // const result = response.data
      // this.semester1 = result.filter(x => x.semester === 'semester 1')
      // this.semester2 = result.filter(x => x.semester === 'semester 2')
      // this.semester1 = result.filter(x => x.semester === 'semester 1')
      // this.semester2 = result.filter(x => x.semester === 'semester 2')
      // this.semaines = response.data
      this.load = 'false'
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
